<template>
    <div class="auth-wrapper auth-v1"
         style="padding-bottom:0px;padding-top:0px;">
        <div class="auth-inner main-container-width">
            <v-row>

                <v-col cols="12"
                       sm="12"
                       md="12"
                       lg="12"
                       class="mx-auto">

                    <v-card flat
                            class="bg-gradient">
                        <AppHeaderOffers :selectedLanguage="selectedLanguage"
                                         :isSavedOffers="isSaved"
                                         @details="checkOffers($event)"></AppHeaderOffers>
                        <div class="text-center">
                            <v-card class="mt-8 text-center offers-tabs-main offers-theme balance-card-background"
                                    style="padding: 13px 8px;">
                                <ul v-if="!isSaved"
                                    class="offers-tab">

                                    <li v-for="(cats, idx) in categories"
                                        @click="filterByCategory(cats.categoryName)"
                                        style="cursor: pointer;">
                                        <span :class="{ offerimgactive: category == cats.categoryName }"
                                              class="offer-img">
                                            <img :src="cats.image"
                                                 class="cat-image">
                                        </span>
                                        <br>
                                        <p class="text-color-black category-width">{{ $t(cats.categoryName) }}</p>
                                    </li>
                                </ul>
                                <div class="mt-5 mx-2">
                                    <v-text-field outlined
                                                  type="text"
                                                  :placeholder="$t('Search')"
                                                  v-model="searchKeyword"
                                                  @keyup="filterTopics"
                                                  hide-details="auto"></v-text-field>
                                </div>
                            </v-card>
                        </div>
                        <v-card>
                            <div class="mx-5">
                                <div class="my-5 balance-card-background"
                                     v-if="offers.length != 0 && loaded">
                                    <h2 class="my-5"
                                        style="font-size: 18px;padding-top: 14px;padding-left: 30px;padding-right: 30px;">
                                        {{ isSaved ? $t('Saved Offers') : $t('Available Offers') }}</h2>
                                    <offersCard :offers="offers"></offersCard>
                                </div>

                                <div class="my-5 balance-card-background text-center"
                                     v-if="offers.length == 0">
                                    <div class="no-offers">

                                        <img src="@/assets/images/logos/Warning.png"
                                             alt="">
                                        <h2 v-if="!isSaved"
                                            class="text-color-black mt-1">
                                            {{ $t('No offers') }}
                                        </h2>
                                        <h2 v-if="isSaved"
                                            class="text-color-black mt-1">
                                            {{ $t('No saved offers') }}
                                        </h2>
                                        <p v-if="isSaved"
                                           class="text-color-black mt-0">
                                            {{ $t(`You currently have no saved offers. Add offers to your card by viewing
                                            available offers`) }}
                                        </p>

                                        <v-btn v-if="isSaved"
                                               class="offers-btn"
                                               @click="loggedOffers"
                                               style="padding: 21px 0px;">
                                            {{ $t('Show available offers') }}
                                        </v-btn>
                                    </div>

                                </div>

                            </div>
                        </v-card>
                    </v-card>
                </v-col>
            </v-row>

        </div>

    </div>
</template>

<script>
import AppHeaderOffers from '../test/AppHeaderOffers.vue'
import offersCard from '../test/offersCard.vue'
import offerServices from '@/services/offers-api-services'
import sharedServices from '@/services/shared-services';
import store from '@/store';
import moment from 'moment'
export default {
    components: {
        AppHeaderOffers,
        offersCard
    },
    data: () => ({
        empty: false,
        searchKeyword: '',
        offers: [],
        isSaved: false,
        tempOffers: [],
        unsubscribe: null,
        loadedCount: 0,
        saved: false,
        loaded: true,
        allOffers: [],
        category: 'All Categories',
        selectedLanguage: 'en',
        heading: 'Available Offers',
        categories: [
            { categoryName: 'All Categories', image: require('@/assets/images/offers/allffers.png') },
            { categoryName: 'Dinning', image: require('@/assets/images/offers/Dining-Filled.svg') },
            { categoryName: 'Entertainment', image: require('@/assets/images/offers/Entertainment-Filled.svg') },
            { categoryName: 'Shopping', image: require('@/assets/images/offers/Merchandise-Filled.svg') },
            { categoryName: 'Traveling', image: require('@/assets/images/offers/Travel-Bag-Filled.svg') },
            // { categoryName: 'Cities', image: require('@/assets/images/offers/Dining-Filled.svg') },
        ],
        requestInfo: {
            requestUID: (Math.floor(1000 + Math.random() * 9000)).toString(),
            requestDate: store.get('requestKeys/requestDate'),
            identityKey: store.get('requestKeys/identityKey'),
            channelId: store.get('requestKeys/channelId'),
            channelKey: store.get('requestKeys/channelKey'),
            identitySeed: store.get('requestKeys/identitySeed'),
            userName: store.get('requestKeys/userName'),
        },
    }),
    created()
    {
        this.unsubscribe = store.subscribe((mutation) =>
        {
            // console.log(mutation);
            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
                if (store.get('requestKeys/accessToken')  && this.loadedCount == 0)
                {
                    
                    if (this.isSaved)
                    {
                        this.savedOffers();
                    }
                    else
                    {
                        this.loggedOffers();
                    }
                    this.loadedCount++;
                }

            }
        })
    },
    destroyed()
    {
        this.unsubscribe();
    },
    mounted()
    {
        let lang = localStorage.getItem('language');
        if (lang)
        {
            this.selectedLanguage = localStorage.getItem('language');
        }
        this.loggedOffers();
    },
    methods: {
        checkOffers(event)
        {
            if (event)
            {
                this.savedOffers();
            }
            else
            {
                this.loggedOffers();
            }
        },
        filterTopics()
        {
            this.offers = this.tempOffers.filter(top =>
            {
                if (top.merchants[0])
                {
                    return top.merchants[0]?.merchantName.toLowerCase().includes(this.searchKeyword.toLowerCase());
                }
            });
        },
        async savedOffers()
        {
            store.dispatch('loader/show');
            await sharedServices.getToken('entity', store.get('requestKeys/userName')).then(tokenRes =>
            {
                store.set('requestKeys/channelKey', tokenRes.data.results.channelKey);
                store.set('requestKeys/identityKey', tokenRes.data.results.identityKey);
                store.set('requestKeys/requestDate', tokenRes.data.results.requestDate);
            });

            let data = {
                requestInfo: this.requestInfo,
                clientCode: store.get('requestKeys/ClientCode'),
                reference: 'C',
                language: localStorage.getItem('language') == 'en' ? 'E' : 'A',
            }
            this.isSaved = true;

            offerServices.GetSavedoffers(data).then(res =>
            {

                if (res.data.responseInfo.errorCode == '00000')
                {
                    this.isSaved = true;
                    this.allOffers = res.data.savedOffers;

                    this.allOffers.forEach(element =>
                    {
                        element['image'] = 'https://online.americanexpress.com.sa/smartphoneapi/OffersV1/OfferImages/' + element.offerImage;
                        element['expiryDate'] = moment(new Date(element.offerEndDate)).format('DD/MMM/YYYY');
                        element['isSaved'] = true;

                        if (element.shortDescription.length > 35)
                        {
                            element.shortDescription = (element.shortDescription).substring(0, 35) + '...'
                        }
                    });
                    this.offers = this.allOffers;
                    this.tempOffers = this.offers;

                    this.category = 'All Categories';
                    this.filterByCategory(this.category);
                }
                else
                {
                    this.allOffers = [];
                    this.isSaved = true;
                    this.offers = [];
                    this.tempOffers = [];
                }
            })
            this.heading = 'Saved Offers';
            this.loadedCount = 0;
        },
        checkSavedOffers()
        {
            store.dispatch('loader/show');
            let data = {
                requestInfo: this.requestInfo,
                clientCode: store.get('requestKeys/ClientCode'),
                reference: 'C',
                language: 'E',
            };

            offerServices.GetSavedoffers(data).then(res =>
            {
                if (res.data.responseInfo.errorCode == '00000')
                {
                    this.offers.forEach(elementTwo =>
                    {
                        res.data.savedOffers.forEach(element =>
                        {
                            if (element.offerSerno == elementTwo.offerSerno)
                            {
                                elementTwo['isSaved'] = true;
                            }
                        });
                    });
                }
                else
                {

                }
                this.loaded = true;
                this.loadedCount = 0;

                this.category = 'All Categories';
                this.filterByCategory(this.category);
            });
        },
        async loggedOffers()
        {
            store.dispatch('loader/show');
            await sharedServices.getToken('entity', store.get('requestKeys/userName')).then(tokenRes =>
            {
                store.set('requestKeys/channelKey', tokenRes.data.results.channelKey);
                store.set('requestKeys/identityKey', tokenRes.data.results.identityKey);
                store.set('requestKeys/requestDate', tokenRes.data.results.requestDate);
            });

            this.loaded = false;
            this.isSaved = false;
            let data = {
                requestInfo: this.requestInfo,
                clientCode: store.get('requestKeys/ClientCode'),
                country: "",
                city: "",
                merchant: "",
                category: 0,
                language: localStorage.getItem('language') == 'en' ? 'E' : 'A',
            };

            offerServices.GetLoggedoffers(data).then(res =>
            {
                if (res.data.responseInfo.errorCode == '00000')
                {
                    this.isSaved = false;
                    this.allOffers = res.data.listOffers
                    this.allOffers.forEach(element =>
                    {
                        element['image'] = 'https://online.americanexpress.com.sa/smartphoneapi/OffersV1/OfferImages/' + element.offerImage;
                        // element['expiryDate'] = moment(new Date(element.offerEndDate + ' 12:00:00 AM')).format('DD MMM YYYY');



                        if (element.offerEndDate)
                        {
                            let dates = element.offerEndDate.split('/');

                            let newDate = moment(dates[1] + ' ' + dates[0] + ' ' + dates[2]).format('DD/MMM/YYYY');

                            element['expiryDate'] = newDate;
                            element.offerEndDate = newDate;
                        }


                        if (element.shortDescription.length > 35)
                        {
                            element.shortDescription = (element.shortDescription).substring(0, 35) + '...';
                        }
                        element['isSaved'] = false;
                    });
                    this.offers = this.allOffers;
                    this.tempOffers = this.offers;

                    this.checkSavedOffers();
                }
                else
                {
                    this.allOffers = [];
                    this.isSaved = false;
                    this.offers = [];
                    this.tempOffers = [];
                }
            })
        },
        filterByCategory(val)
        {
            this.category = val
            this.offers = [];
            this.allOffers.forEach(element =>
            {
                if (val == 'All Categories')
                {
                    this.offers.push(element)
                }
                else if (val == element.offerCategoryName)
                {
                    this.offers.push(element)
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.cat-image {
    margin-bottom: -3px;
    width: 17px;
    height: 17px
}

.offers-tab {
    padding-left: 0px;

    li {
        list-style-type: none;
        display: inline-block;
        padding-right: 11px;
        padding-left: 11px;
        font-size: 14px;
    }

    .category-width {
        width: 98px !important;
    }

    .offer-img {
        background: white;
        padding: 19px 19px;
        border-radius: 40px;
        cursor: pointer;

        img {
            margin-top: 18px;
        }
    }

    .offerimgactive {
        background: #006FCF;
        padding: 19px 19px;
        border-radius: 40px;
        cursor: pointer;

        img {
            margin-top: 18px;
            filter: invert(22%) sepia(68%) saturate(67%) hue-rotate(87deg) brightness(220%) contrast(119%);
        }
    }
}

.offers-tabs-main {
    padding: 12px 0px;
    padding: 12px 14px;
    // position: absolute;
    // right: 22%;
    // left: 18%;
    // top: 114px;
    // z-index: 10;
    margin: auto;
    width: 737px;
}

.no-offers {
    width: 31vw;
    margin: 0 auto;
    padding-top: 75px;
    padding-bottom: 75px;
}

@media screen and (max-width: 808px) {

    .offers-tabs-main {
        padding: 12px 0px;
        width: fit-content;
        position: relative !important;
        right: 0 !important;
        left: 0 !important;
        top: 0 !important;

    }

    .no-offers {
        width: 70vw;
        margin: 0 auto;
        padding-top: 75px;
        padding-bottom: 75px;
    }

}

@media screen and (max-width: 1100px) {

    .offers-tabs-main {
        padding: 12px 0px;

        position: relative !important;
        right: 0 !important;
        left: 0 !important;
        top: 0 !important;

    }

}

@media screen and (max-width: 1280px) {

    .offers-tabs-main {

        right: 18.8%;
        left: 17.3%;

    }

}

@media screen and (max-width: 417px) {
    .category-width {
        width: fit-content !important;
    }
}

.text-color-black {
    color: #00175A !important;
    margin-bottom: 0px;
    margin-top: 20px;
}

.offers-btn {
    color: #006FCF;
    border: 1px solid #006FCF;
    width: 202px;
    margin: 0 auto;
    padding: 10px 0px;
    border-radius: 12px;
    margin-top: 20px;
    cursor: pointer;
}
</style>
